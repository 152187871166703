import { AppFunctionComponent, TextBuilder } from "../types"
import { graphql } from "gatsby"
import React from "react"
import { SitePageContextPagination } from "../../graphql-types"
import MediaHero from "../components/about/media/media-hero.component"
import QuoteSlider from "../components/common/quote-slider.component"
import Layout from "../components/layout"
import PressReleaseResults from "../components/media/press-release-results/press-release-results"
import SEO from "../components/seo.component"
import { DetectedBreadcrumbs, TopNavigationWrapper } from "../navigation"
import {
  ContentfulHeroSection,
  PageQueryResult,
  PressRelease,
  QueryResult,
  Quote,
} from "../types"
import { Menu } from "../navigation/types/menu.interface"

interface Props
  extends PageQueryResult<{
    pressReleases: QueryResult<PressRelease>
    quotes: {
      embeds: Quote[]
    }
    heroContent: ContentfulHeroSection
    topNavigationContent: Menu
    footerNavigationContent: Menu
    promobarContent: TextBuilder
  }> {
  pageContext: {
    pressReleases: PressRelease[]
    pagination?: SitePageContextPagination
  }
}

const PressReleaseIndexPage: AppFunctionComponent<Props> = ({
  pageContext,
  data: {
    meta,
    quotes,
    heroContent,
    topNavigationContent: { navigationItems: topNavigationItems },
    footerNavigationContent: { navigationItems: footerNavigationItems },
    promobarContent,
  },
}) => {
  const { pressReleases, pagination } = pageContext
  const slides = quotes.embeds

  return (
    <Layout
      topNavigationItems={topNavigationItems}
      footerNavigationItems={footerNavigationItems}
      promobarContent={promobarContent}
      withSmoothScroll
    >
      <SEO {...meta} />
      <TopNavigationWrapper breadcrumbsMenu={<DetectedBreadcrumbs />} />
      <MediaHero content={heroContent} />
      <QuoteSlider slides={slides} />
      <PressReleaseResults
        pressReleases={pressReleases}
        pagination={pagination}
      />
    </Layout>
  )
}

export default PressReleaseIndexPage

export const query = graphql`
  query PressReleaseIndexPage($locale: String!, $language: String!) {
    meta: contentfulPageMeta(page: { eq: "about/media" }) {
      ...PageMeta
    }
    quotes: contentfulSlider(
      entryName: { eq: "Quotes slider" }
      node_locale: { eq: $locale }
    ) {
      embeds {
        ...Quote
      }
    }
    heroContent: contentfulHeroSection(
      contentfulid: { eq: "media" }
      node_locale: { eq: $locale }
    ) {
      primaryTitle
      text {
        raw
        references {
          ...Button
        }
      }
      mobileImage: image {
        gatsbyImageData(
          layout: CONSTRAINED
          placeholder: BLURRED
          quality: 100
          height: 410
        )
      }
      tabletImage: image {
        gatsbyImageData(
          layout: CONSTRAINED
          placeholder: BLURRED
          quality: 100
          height: 560
        )
      }
      desktopImage: image {
        gatsbyImageData(
          layout: CONSTRAINED
          placeholder: BLURRED
          quality: 100
          height: 680
        )
      }
    }
    topNavigationContent: contentfulNavigation(
      contentfulid: { eq: "top-navigation" }
      node_locale: { eq: $locale }
    ) {
      ...Navigation
      node_locale
    }
    footerNavigationContent: contentfulNavigation(
      contentfulid: { eq: "footer-navigation" }
      node_locale: { eq: $locale }
    ) {
      ...Navigation
      node_locale
    }
    promobarContent: contentfulText(
      contentful_id: { eq: "63Ao7XUYTc7UtDipcRpwoc" }
      node_locale: { eq: $locale }
    ) {
      text {
        raw
      }
      node_locale
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
