import React from "react"
import styled, { css } from "styled-components"
import { NavigationArrow } from "./general-styles.component"
import { NeutralRawLink } from "../../ui/components/link/link.component"
import { AppFunctionComponent } from "../../types"

const PaginationWrapper = styled.nav`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
`

const ArrowWrapper = styled.div<{ active: boolean }>`
  z-index: 2;
  width: 26px;
  height: 26px;
  cursor: pointer;
  position: relative;
  box-sizing: border-box;
  opacity: ${({ active }) => (active ? 1 : 0.5)};

  @media (min-width: ${({ theme }) => theme.breakpoint.mobileHorizontal}px) {
    width: 32px;
    height: 32px;
  }

  &:first-of-type {
    ${NavigationArrow} {
      transform: rotateZ(180deg);
    }
  }

  ${({ active }) =>
    !active &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `};

  img {
    width: 100%;
    height: 100%;
  }
`

const PagesList = styled.ul`
  margin: 0;
  display: flex;
  list-style: none;
  padding: 30px 20px;
  align-items: center;
  flex-direction: row;

  @media (min-width: ${({ theme }) => theme.breakpoint.mobileHorizontal}px) {
    padding: 50px 20px;
  }
`

const PagesListItem = styled.li<{ active?: boolean }>`
  width: 26px;
  height: 26px;
  line-height: 26px;
  text-align: center;
  border-radius: 50%;
  font-size: ${12 / 16}rem;
  background-color: ${({ theme, active }) =>
    active && theme.color.backgroundAttention};

  @media (min-width: ${({ theme }) => theme.breakpoint.mobileHorizontal}px) {
    width: 32px;
    height: 32px;
    line-height: 32px;
    font-size: ${14 / 16}rem;
  }
`

const PageListItemDots = styled(PagesListItem)`
  &:before {
    content: "...";
  }
`

interface Pagination {
  current: number
  total: number
  maxVisiblePages: number
}

const getPagesArray = ({ current, total, maxVisiblePages }: Pagination) => {
  const visiblePagesCount = Math.min(total, maxVisiblePages)
  const centerIndexFloor = Math.floor(maxVisiblePages / 2)
  const centerIndexCeil = Math.ceil(maxVisiblePages / 2)
  const addEllipsis = maxVisiblePages >= 5 && total > maxVisiblePages

  return Array.from({ length: visiblePagesCount }, (_, i) => {
    switch (true) {
      case total <= maxVisiblePages:
        return i + 1
      case (addEllipsis && i === 1 && current > centerIndexCeil) ||
        (addEllipsis &&
          i === maxVisiblePages - 2 &&
          current < total - centerIndexFloor):
        return -i
      case addEllipsis && i === 0:
        return 1
      case addEllipsis && i === maxVisiblePages - 1:
        return total
      case current < centerIndexCeil:
        return i + 1
      case current > total - centerIndexFloor && addEllipsis:
        return total - (maxVisiblePages - 1 - i)
      default:
        return current - centerIndexFloor + i
    }
  })
}

const appendToRootUrl = (rootUrl: string, appendix: string | number) => {
  if (appendix) {
    if (rootUrl.endsWith("/")) {
      return `${rootUrl}${appendix}/`
    } else {
      return `${rootUrl}/${appendix}/`
    }
  } else {
    return rootUrl
  }
}

interface Props {
  rootUrl?: string
  query?: string
  total?: number
  current?: number
  maxVisiblePages?: number
  showWhenSingle?: boolean
  scrollTo?: string
}

const Pagination: AppFunctionComponent<Props> = ({
  className = "",
  rootUrl = "/",
  query = "",
  total = 2,
  current = 1,
  maxVisiblePages = 7,
  showWhenSingle = false,
  scrollTo = "",
}) => {
  const pagesArray = getPagesArray({ current, total, maxVisiblePages })
  const previousPageLink =
    current > 1
      ? appendToRootUrl(rootUrl, current === 2 ? "" : current - 1) + query
      : ""
  const nextPageLink =
    current < total ? appendToRootUrl(rootUrl, current + 1) + query : ""
  const showPagination = showWhenSingle ? true : total > 1
  const scrollHash = scrollTo.length === 0 ? "" : `#${scrollTo}`

  return (
    <>
      {showPagination && (
        <PaginationWrapper className={className}>
          <ArrowWrapper active={previousPageLink.length !== 0}>
            <NeutralRawLink
              to={previousPageLink + scrollHash}
              aria-label="Go to previous page"
            >
              <NavigationArrow aria-hidden="true" />
            </NeutralRawLink>
          </ArrowWrapper>
          <PagesList>
            {pagesArray.map((pageNumber) =>
              pageNumber < 0 ? (
                <PageListItemDots key={pageNumber} />
              ) : (
                <PagesListItem key={pageNumber} active={pageNumber === current}>
                  <NeutralRawLink
                    to={
                      appendToRootUrl(
                        rootUrl,
                        pageNumber > 1 ? pageNumber : ""
                      ) +
                      query +
                      scrollHash
                    }
                  >
                    {pageNumber}
                  </NeutralRawLink>
                </PagesListItem>
              )
            )}
          </PagesList>
          <ArrowWrapper active={nextPageLink.length !== 0}>
            <NeutralRawLink
              to={nextPageLink + scrollHash}
              aria-label="Go to next page"
            >
              <NavigationArrow aria-hidden="true" />
            </NeutralRawLink>
          </ArrowWrapper>
        </PaginationWrapper>
      )}
    </>
  )
}

export default Pagination
